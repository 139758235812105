<template>
  <div class="PageBox">
    <NavBar :title="$t('withdraw2[0]')" :page-number="-1"/>
    <div style="position: absolute;top: 12px;right: 7px;z-index: 10;">
      <img src="~@/assets/img/skinbitcoin/task01.png" slot="right" @click="onClickRight"/>
    </div>
    <div class="titlePanel gradient-panel">
      <div style="width: 80%">
        <div style="font-size: 25px;color:var(--font_color);font-weight: bold"> {{ Number(UserInfo.balance2).toFixed(2) }} </div>
        <div style="color: #8f9bd2"> {{ $t("wallet.default[9]") }}</div>
        <div style="color: #8f9bd2">{{InitData.usdtinfo.sign}}</div>
      </div>
      <div style="margin-top: 20px;">
        <img style="width: 57px;height: 57px;" src="~@/assets/img/skinbitcoin/package.png" alt="">
      </div>
    </div>
      <div class="box">

        <van-tabs  title-active-color="var(--tabs-title-color)" color="var(--tab-line-color)"
                   title-inactive-color="var(--tabs-title-inactive-color)" :ellipsis="false"
                   :border="false" v-model="tabsIndex" :line-width="108">
          <van-tab style="padding: 0 0px;width: 135px;margin-left: -25px">
            <template #title>
              <div>
                <p style="font-size: 16px">{{ $t("wallet.label[8]") }}</p>
              </div>
            </template>
          </van-tab>
          <van-tab style="padding: 0 0px;">
            <template #title>
              <div>
                <p style="font-size: 16px; width: 102px;">{{ $t("wallet.label[9]") }}{{InitData.usdtinfo.sign}}</p>
              </div>
            </template>
          </van-tab>
        </van-tabs>
        <div class="Panel">
          <div v-if="tabsIndex == 0">
            <div class="border-grey" style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: 248px; color: var(--bc-text-color-1)"> {{$t('wallet.label[1]')}} USDT </div>
              <div>
                {{usdtAmount}}
              </div>
            </div>
          </div>

          <div v-if="tabsIndex == 1" class="border-grey" style="display: flex;flex-direction: column;align-items: center;">
            <div style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: 148px; color: var(--bc-text-color-1)">{{$t('bindAccount.fields[3]')}}</div>
              <div>
                <van-field
                    v-model="BankInfo.bank_name" readonly :placeholder="$t('bindAccount.placeholder[3]')"
                />
              </div>
            </div>
            <div style="border-bottom: 1px solid #707070; width: 98%;"></div>


            <div style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: 148px; color: var(--bc-text-color-1)">{{$t('bindAccount.fields[0]')}}</div>
              <div>
                <van-field
                    v-model="BankInfo.name" readonly
                    :placeholder="$t('bindAccount.placeholder[0]')"
                />
              </div>
            </div>
            <div style="border-bottom: 1px solid #707070; width: 90%;"></div>


            <div style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: 148px; color: var(--bc-text-color-1)">{{$t('bindAccount.fields[2]')}}</div>
              <div>
                <van-field
                    v-model="BankInfo.card_no" readonly
                    :placeholder="$t('bindAccount.placeholder[2]')"
                />
              </div>
            </div>
            <div style="border-bottom: 1px solid #707070; width: 90%;"></div>



            <div style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: 148px; color: var(--bc-text-color-1)">{{$t('bindAccount.fields[1]')}}</div>
              <div>
                <van-field
                    v-model="BankInfo.phone" readonly
                    :placeholder="$t('bindAccount.placeholder[1]')"
                />
              </div>
            </div>
            <div style="border-bottom: 1px solid #707070; width: 90%;"></div>


            <div style="display: flex;  padding: 12px 12px; align-items: center; ">
              <div style="align-items: center; width: auto; color: var(--bc-text-color-1)">
                {{ $t('wallet.label[1]') }}
              </div>
              <div style="display: flex">
                <van-field v-model="amount"
                           :placeholder="$t('wallet.placeholder[1]')"
                />

              </div>
              <div style="width: 68px;">
                <van-button class="van-button2" size="min" style="height: 30px;background-color: #F0A903FF; border: 0px; color: #0b273f;"
                            @click="setAmount">
                  <span>{{ $t('sell.label[8]') }}</span></van-button>
              </div>
            </div>


        </div>
        </div>
      </div>
        <div class="centerPanel" style="text-align: center;margin-top: 30px">
          <van-button class="confirmBtn gradient-btn" @click="verifySubmitDraw">{{$t('recharge.default[4]')}}</van-button>
        </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      amount:0,
      BankInfo:{},
      tabsIndex: 0,
    };
  },
  computed: {
    usdtAmount(){
      return parseFloat(this.amount/this.InitData.USDTUSDT).toFixed(2);
    }
  },
  watch: {},
  created() {
    this.$Model.GetUserInfo()
    this.$toast.loading();
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
            this.BankInfo = data['data'][0];
          }
        }
    );
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/withdrawlist");
    },
    setAmount(){
      this.amount = this.UserInfo.balance2
      // this.BankInfo.money = 333
    },
    submitDraw() {
      let postData = this.BankInfo;
      postData['money'] = parseFloat(this.amount).toFixed(2);
      postData['coin_price'] = this.usdtAmount;
      this.$Model.Draw(postData, (data) => {
        // this.isSubmit = false;
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          case -2:
            msg = this.$t('login.codes[2]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.push("/user");
        }
      });
    },
    verifySubmitDraw(){
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['shimingauth']==1){
            this.$router.push("/shimingauth");
            return ;
          }
          this.submitDraw();
        }
      })
    },
  },
};
</script>

<style scoped>
.PageBox >>> .van-nav-bar__title{
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
}

.PageBox {
  overflow: auto;
  padding-top: 60px;

}


.titlePanel {
  margin: 10px;
  border-radius: 10px;
  line-height: 30px;
  padding: 20px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
}

.box .van-cell{
  padding-left: 15px;

}

.van-cell >>> .van-field__control {
  color: var(--bc-text-color) !important;
  padding-left: 7px;
  height: 100%;
}

.van-cell >>> .van-field__control::-webkit-input-placeholder {
  color: var(--cell_placeholder) !important;
  font-size: 19px;
  ;
}

.van-cell >>> .van-field__control::-moz-placeholder {
  color: var(--cell_placeholder) !important;
  ;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
  color: var(--cell_title) ;
}
.PageBox .box {
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  border-radius: 10px;
  margin: 10px;
background-color: var(--panel_color);
  color: #555;
}


.van-cell {
  background-color: transparent;
}

p{
  width: 80px;
}


.btn {
  width: 85%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 30px 30px;
}

.confirmBtn {
  width:279px;
  height:42px;
}
</style>
